<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    class="thin-table"
    hide-default-footer
    disable-sort
    dense
  >
    <template #item.name="{ item }">
      <div class="py-1">
        <router-link :to="itemLink(item)">
          {{ item.name }}
        </router-link>
      </div>
      <v-breadcrumbs :items="ancestors(item)" class="pa-0 mb-1">
        <template #item="{ item }">
          <v-breadcrumbs-item>
            <span class="caption font-italic blue-grey--text lighten-1">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template #item.image="{ item }">
      <v-popup-image
        class="my-2"
        :src-preview="item.images.suggest.preview"
        :src-original="item.images.suggest.original"
      />
    </template>
    <template #item.message="{ item }">
      <div class="font-italic">{{ item.message }}</div>
    </template>
  </v-data-table>
</template>

<script>
import { catalogItemsTableMixin } from './mixins'

export default {
  mixins: [catalogItemsTableMixin],
  props: {
    actions: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.headers = [
      {
        text: 'Код 1С',
        value: '_1c_id',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Название',
        value: 'name',
        sortable: false,
        align: 'left',
        width: '100%'
      },
      {
        text: 'Изображение',
        value: 'image',
        sortable: false,
        align: 'center',
        width: '110'
      },
      {
        text: 'Причина отклонения',
        value: 'message',
        sortable: false,
        align: 'left',
        width: '200px'
      }
    ]
  }
}
</script>
