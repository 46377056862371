<template>
  <div>
    <div class="title mb-3">Статистика загрузок</div>
    <!-- Лоадер - загрузка элементов -->
    <v-overlay color="white" :value="getUploadStatsFetching" absolute>
      <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-data-table
        :headers="$options.header"
        :items="getUploadStats"
        show-expand
        hide-default-footer
        item-key="name"
        :search="search"
        :items-per-page="getUploadStats.length"
        :expanded.sync="expanded"
        class=""
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Поиск"
            class="mx-4"
        ></v-text-field>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="payment">
          <div v-if="item.last_payment" class="payment__wrapper">
            <div class="payment__info">
              <span class="payment__date">
                С
                <strong>
                  {{ getLocalTime(new Date(item.last_payment.created)) }}
                </strong>
              </span>
              <span class="payment__date">
                По настоящее время
                </span>
              <span>
                  Загружено: {{ item.count_uploads - item.last_payment.moderated_images_count }}
                </span>
            </div>
            <v-btn
                :disabled="(item.count_uploads - item.last_payment.moderated_images_count) === 0 || !hasPayPermission"
                @click="savePaymentStatistic(item)"
                color="success">
              Выплатить
            </v-btn>
          </div>
          <div v-else>
            Выплат еще не было.
            <v-btn
                @click="savePaymentStatistic(item)"
                color="success">
              Выплатить
            </v-btn>
          </div>
        </td>
      </template>
      <template #item.showStatistic="{ item }">
        <PaymentStatistic :user="item"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import PaymentStatistic from "@/views/images/dialogs/PaymentStatistic.vue";
import permissions from "@/common/permissions";

export default {
  components: {PaymentStatistic},
  header: [
    {text: 'Сотрудник', value: 'name'},
    {text: 'Количество загрузок', value: 'count_uploads'},
    {text: 'Статистика', value: 'showStatistic'},
    {text: '', value: 'data-table-expand'},
  ],
  created() {
    this.actionFetchUploadStats()
  },
  destroyed() {
    this.actionClearUploadStats()
  },
  data() {
    return {
      expanded: [],
      search: ''
    }
  },
  computed: {
    ...mapGetters('images', ['getUploadStats', 'getUploadStatsFetching']),
    ...mapGetters('auth', [
      'currentUser', 'getCurrentUserHasPermission'
    ]),
    hasPayPermission: function () {
      return this.getCurrentUserHasPermission(permissions.apiModerationPayment)
    },
  },
  methods: {
    ...mapActions('images', [
      'actionFetchUploadStats',
      'actionClearUploadStats',
      'actionSavePaymentStatistic'
    ]),
    savePaymentStatistic(item) {
      this.actionSavePaymentStatistic({
        userForId: item.id,
        moderatedById: this.currentUser.id,
        countModerated: item.count_uploads,
      });
      this.actionFetchUploadStats();
    },
    getLocalTime(date) {
      return date.toLocaleDateString()
    }
  }
}
</script>

<style scoped lang="scss">
.payment {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>

<style>
.payment {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
</style>