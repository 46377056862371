<template>
  <div>
    <div class="title mb-3">Поиск по коду 1с</div>

    <v-form ref="form" @submit.prevent="searchHandler">
      <v-row>
        <v-col cols="12" sm="8" md="9" lg="10">
          <v-text-field
            v-model="search"
            :rules="rules.search"
            label="Код 1с"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn type="submit" color="primary" depressed block>
            Найти
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Лоадер - загрузка элементов -->
    <v-overlay color="white" :value="getStockProductsFetching" absolute>
      <v-progress-circular
        :size="50"
        color="indigo lighten-4"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- Таблица товаров -->
    <div v-if="productItems.length" class="mb-6">
      <div class="subtitle-2 mb-1 mt-3">Товары</div>
      <SearchTable
        :items="productItems"
        :type="catalogTypes.product"
        :items-per-page="1"
      >
        <template #actions="{ item }">
          <SearchUploadImage :item="item" :type="catalogTypes.product" />
        </template>
      </SearchTable>
    </div>

    <!-- Таблица разделов -->
    <div v-if="structureItems.length" class="mb-6">
      <div class="subtitle-2 mb-1 mt-3">Папки номенклатуры</div>
      <SearchTable
        :items="structureItems"
        :type="catalogTypes.structure"
        :items-per-page="1"
      >
        <template #actions="{ item }">
          <SearchUploadImage :item="item" :type="catalogTypes.structure" />
        </template>
      </SearchTable>
    </div>

    <!-- Таблица веток ассортимента -->
    <div v-if="categoryItems.length" class="mb-6">
      <div class="subtitle-2 mb-1 mt-3">Ветки ассортимента</div>
      <SearchTable
        :items="categoryItems"
        :type="catalogTypes.category"
        :items-per-page="1"
      >
        <template #actions="{ item }">
          <SearchUploadImage :item="item" :type="catalogTypes.category" />
        </template>
      </SearchTable>
    </div>

    <div
      v-if="
        !getStockProductsFetching &&
          !productItems.length &&
          !structureItems.length &&
          !categoryItems.length
      "
      class="mt-2 body-2 font-italic text-center"
    >
      Нет результатов поиска
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import { routes } from '@/router'
import SearchTable from './SearchTable'
import SearchUploadImage from './SearchUploadImage'

export default {
  components: { SearchTable, SearchUploadImage },
  created() {
    this.routes = routes
    this.catalogTypes = catalogTypes
  },
  data: () => ({
    search: null,
    productItems: [],
    structureItems: [],
    categoryItems: [],
    rules: {
      search: [v => (v || '').length > 0 || 'Введите 1с код']
    }
  }),
  computed: {
    ...mapGetters('stock_tree', ['getStockTreeById1C']),
    ...mapGetters('stock_categories', ['getStockCategoryById1C']),
    ...mapGetters('stock_products', [
      'getStockProductsByParams',
      'getStockProductsFetching'
    ])
  },
  methods: {
    ...mapActions('stock_products', ['actionFetchStockProducts']),
    searchHandler: function() {
      this.actionFetchStockProducts({
        filter_by_1c_id: this.search
      }).then(() => {
        // Товары
        this.productItems = this.getStockProductsByParams({
          _1c_id: this.search
        })
        // Папки номенклатуры
        const structureItems = this.getStockCategoryById1C(this.search)
        this.structureItems = structureItems ? [structureItems] : []
        // Ветки ассортимента
        const categoryItems = this.getStockTreeById1C(this.search)
        this.categoryItems = categoryItems ? [categoryItems] : []
      })
    }
  }
}
</script>
