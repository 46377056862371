<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    class="thin-table"
    hide-default-footer
    disable-sort
    dense
  >
    <template #item._1c_id="{ item }">
      <div v-if="item._1c_id">{{ item._1c_id }}</div>
      <div v-else class="font-italic font-weight-light">нет</div>
    </template>
    <template #item.info="{ item }">
      <div
        v-if="type == catalogTypes.product"
        class="pt-1 blue-grey--text lighten-2"
      >
        Артикул: {{ itemArticle(item) }}
      </div>
      <div class="py-1">
        <router-link :to="itemLink(item)">
          {{ item.name }}
        </router-link>
      </div>
      <v-breadcrumbs :items="ancestors(item)" class="pa-0 mb-1">
        <template #item="{ item }">
          <v-breadcrumbs-item>
            <span class="caption font-italic blue-grey--text lighten-1">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template #item.search="{ item }">
      <div>
        <v-icon class="mr-1" small>mdi-magnify</v-icon>
        <a
          :href="
            encodeURI(`http://images.yandex.ru/yandsearch?text=${item.name}`)
          "
          target="_blank"
        >
          Яндекс
        </a>
      </div>
      <div class="pt-1">
        <v-icon class="mr-1" small>mdi-magnify</v-icon>
        <a
          :href="
            encodeURI(
              `https://www.google.ru/search?newwindow=1&source=lnms&tbm=isch&q=${item.name}`
            )
          "
          target="_blank"
        >
          Google
        </a>
      </div>
    </template>
    <template #item.image="{ item }">
      <v-popup-image
        v-if="itemImage(item)"
        class="my-2"
        :src-preview="itemImage(item).preview"
        :src-original="itemImage(item).original"
      />
      <div v-else class="font-weight-light font-italic">нет</div>
    </template>
    <template #item.action="{ item }">
      <slot name="actions" :item="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
import * as catalogTypes from '@/common/catalogTypes'
import { catalogItemsTableMixin } from './mixins'

export default {
  mixins: [catalogItemsTableMixin],
  created() {
    this.catalogTypes = catalogTypes
    this.headers = [
      {
        text: 'Код 1С',
        value: '_1c_id',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Информация',
        value: 'info',
        sortable: false,
        align: 'left',
        width: '100%'
      },
      {
        text: '',
        value: 'search',
        sortable: false,
        align: 'center',
        width: 100
      },
      {
        text: 'Изображение',
        value: 'image',
        sortable: false,
        align: 'center',
        width: 110
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'center'
      }
    ]
  },
  methods: {
    itemImage: function(item) {
      const hasImages =
        this.type === catalogTypes.product
          ? item.images.length > 0
          : !!item.preview
      if (hasImages) {
        return this.type === catalogTypes.product
          ? { preview: item.images[0].url, original: item.images[0].url }
          : { preview: item.preview, original: item.preview }
      }
      return null
    }
  }
}
</script>
