<template>
  <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      item-key="candidate_id"
      class="thin-table"
      hide-default-footer
      disable-sort
      dense
  >
    <template #item._1c_id="{ item }">
      <div v-if="item._1c_id">{{ item._1c_id }}</div>
      <div v-else class="font-italic font-weight-light">нет</div>
    </template>
    <template #item.info="{ item }">
      <div
          v-if="type == catalogTypes.product"
          class="pt-1 blue-grey--text lighten-2"
      >
        Артикул: {{ itemArticle(item) }}
      </div>
      <div class="py-1">
        <router-link :to="itemLink(item)">
          {{ item.name }}
        </router-link>
      </div>
      <v-breadcrumbs :items="ancestors(item)" class="pa-0 mb-1">
        <template #item="{ item }">
          <v-breadcrumbs-item>
            <span class="caption font-italic blue-grey--text lighten-1">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template #item.image-current="{ item }">
      <a-popup-image
          v-if="item.images.current"
          class="my-2"
          :image="item.images.current.original"
          :image-preview="item.images.current.preview"
      />
      <div v-else class="font-weight-light font-italic">нет</div>
    </template>
    <template #item.image-new="{ item }">
      <a-popup-image
          class="my-2"
          :image="item.images.suggest.original"
          :image-preview="item.images.suggest.preview"
      />
    </template>
    <template #item.actions="{ item }">
      <slot name="actions" :item="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
import * as catalogTypes from '@/common/catalogTypes'
import {catalogItemsTableMixin} from './mixins'
import APopupImage from "@/components/APopupImage.vue";

export default {
  components: {APopupImage},
  mixins: [catalogItemsTableMixin],
  props: {
    actions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.catalogTypes = catalogTypes
    const headers = [
      {
        text: 'Код 1С',
        value: '_1c_id',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Информация',
        value: 'info',
        sortable: false,
        align: 'left',
        width: '100%'
      },
      {
        text: 'Текущее',
        value: 'image-current',
        sortable: false,
        align: 'center',
        width: '110'
      },
      {
        text: 'Новое',
        value: 'image-new',
        sortable: false,
        align: 'center',
        width: '110'
      }
    ]
    if (this.actions) {
      headers.push({
        text: 'Действия',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '120'
      })
    }
    return {headers}
  }
}
</script>
