<template>
  <div>
    <div class="title mb-3">Элементы каталога без изображений</div>
    <v-tabs class="mb-6" center-active show-arrows grow>
      <v-tab :to="{ name: routes.imagesEmpty, params: { type: catalogTypes.product } }" exact link>
        Товары
      </v-tab>
      <v-tab :to="{ name: routes.imagesEmpty, params: { type: catalogTypes.structure } }" exact link>
        Папки 1С
      </v-tab>
      <v-tab :to="{ name: routes.imagesEmpty, params: { type: catalogTypes.category } }" exact link>
        Ветки ассортимента
      </v-tab>
      <v-tab :to="{ name: routes.imagesEmpty, params: { type: catalogTypes.brand } }" exact link>
        Бренды
      </v-tab>
    </v-tabs>

    <!-- Лоадер - загрузка элементов -->
    <v-overlay color="white" :value="getImagesEmptyFetching" absolute>
      <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- Таблица -->
    <NoImagesTable :items="items" :type="type" :items-per-page="itemsPerPage">
      <template #actions="{ item }">
        <a @click.stop="openDialog(item)" color="primary" small depressed>
          Загрузить с ПК
        </a>
      </template>
    </NoImagesTable>

    <div v-if="pageCount" class="text-center pt-4">
      <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="12"
      ></v-pagination>
    </div>

    <!-- Модальное окно выбра файла -->
    <v-dialog v-model="uploadDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Загрузить избражение</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-bold">{{ typeTitle }}:</span>
          {{ selectedItem && selectedItem.name }}

          <v-file-input
              v-model="file"
              accept="image/png, image/jpeg, image/jpg"
              prepend-icon="mdi-camera"
              label="Выберите изображение"
              class="mt-3"
              hide-details
              outlined
              dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeDialog" color="blue darken-1" text>
            Закрыть
          </v-btn>
          <v-btn
              @click.stop="uploadRequest"
              :disabled="!file"
              color="blue darken-1"
              text
          >
            Отправить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="getUploading" absolute>
          <v-progress-circular
              :size="50"
              color="indigo lighten-4"
              indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import {routes} from '@/router'
import NoImagesTable from './NoImagesTable'

export default {
  components: {NoImagesTable},
  props: {
    type: {
      type: String,
      default: catalogTypes.product
    }
  },
  created() {
    this.routes = routes
    this.catalogTypes = catalogTypes
    this.fetchItems()
  },
  destroyed() {
    this.actionClearImagesEmpty()
  },
  watch: {
    type: function () {
      this.page = 1
      this.actionClearImagesEmpty()
    },
    computedWatch: function () {
      this.fetchItems()
    },
    getIncrementApplyFilter() {
      this.fetchItems();
    }
  },
  data: () => ({
    uploadDialog: false,
    selectedItem: null,
    file: null,
    page: 1,
    itemsPerPage: 10
  }),
  computed: {
    ...mapGetters('images', [
      'getImagesEmpty',
      'getImagesEmptyFetching',
      'getImagesEmptyCount',
      'getUploading',
      'getNoImagesFilter',
      'getIncrementApplyFilter',
    ]),
    ...mapGetters('stock_tree', ['getStockTreeById']),
    ...mapGetters('stock_categories', ['getStockCategoryById']),
    computedWatch: function () {
      return {
        page: this.page,
        type: this.type
      }
    },
    typeTitle: function () {
      switch (this.type) {
        case catalogTypes.product:
          return 'Товар'
        case catalogTypes.structure:
          return 'Папка 1С'
        case catalogTypes.category:
          return 'Ветка ассортимента'
        case catalogTypes.brand:
          return 'Бренд'
      }

      return ''
    },
    items: function () {
      return this.getImagesEmpty.map(item => {
        const itemData = {
          _1c_id: item._1c_id,
          id: item.id,
          name: item.name,
        }
        if (this.type === catalogTypes.product) {
          itemData.article = item.article
          itemData.structure = item.structure
          itemData.category = item.category
          itemData.brand = item.brand
        } else if (this.type === catalogTypes.structure) {
          itemData.parent = this.getStockCategoryById(item.parent);
        } else if (this.type === catalogTypes.category) {
          itemData.parent = this.getStockTreeById(item.parent);
        }
        return itemData
      })
    },
    pageCount: function () {
      return Math.ceil(this.getImagesEmptyCount / this.itemsPerPage)
    }
  },
  methods: {
    ...mapActions('images', [
      'actionFetchImagesEmpty',
      'actionClearImagesEmpty',
      'actionUploadImage',
    ]),
    fetchItems: function () {
      this.actionFetchImagesEmpty({
        type: this.type,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        ...this.getNoImagesFilter
      })
    },
    openDialog: function (item) {
      this.uploadDialog = true
      this.selectedItem = item
    },
    closeDialog: function () {
      this.uploadDialog = false
      this.selectedItem = null
      this.file = null
    },
    uploadRequest: function () {
      if (this.selectedItem && this.file) {
        this.actionUploadImage({
          type: this.type,
          id: this.selectedItem.id,
          file: this.file
        }).then(() => {
          this.fetchItems()
          this.closeDialog()
        })
      }
    }
  }
}
</script>
