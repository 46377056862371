<template>
  <div>
    <div class="title mb-3">
      Элементы каталога с некачественными изображениями
    </div>
    <v-tabs class="mb-6" center-active show-arrows grow>
      <v-tab :to="{ name: routes.imagesBad, params: { type: catalogTypes.product } }" exact link>
        Товары
      </v-tab>
      <v-tab :to="{ name: routes.imagesBad, params: { type: catalogTypes.structure } }" exact link>
        Папки 1С
      </v-tab>
      <v-tab :to="{ name: routes.imagesBad, params: { type: catalogTypes.category } }" exact link>
        Ветки ассортимента
      </v-tab>
      <v-tab :to="{ name: routes.imagesBad, params: { type: catalogTypes.brand } }" exact link>
        Бренды
      </v-tab>
    </v-tabs>

    <!-- Лоадер - загрузка элементов -->
    <v-overlay color="white" :value="getImagesBadFetching" absolute>
      <v-progress-circular :size="50" color="indigo lighten-4" indeterminate/>
    </v-overlay>

    <!-- Таблица -->
    <BadImagesTable :items="items" :type="type" :items-per-page="itemsPerPage">
      <template #actions="{ item }">
        <v-btn v-if="isAdmin" @click.stop="openApprovalDialog(item)" color="success" x-small depressed>
          Одобрить
        </v-btn>
        <v-btn @click.stop="openUploadDialog(item)" color="primary" class="mt-3" x-small depressed>
          Загрузить
        </v-btn>
        <v-btn v-if="isAdmin" @click.stop="openDeleteImageDialog(item)" color="error" class="mt-3" x-small depressed>
          Удалить
        </v-btn>
      </template>
    </BadImagesTable>

    <div v-if="pageCount" class="text-center pt-4">
      <v-pagination v-model="page" :length="pageCount" total-visible="12"/>
    </div>

    <!-- Модальное окно выбра файла -->
    <v-dialog v-model="uploadDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Загрузить избражение</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-bold">{{ typeTitle }}:</span>
          {{ selectedItem && selectedItem.name }}
          <v-file-input
            v-model="file"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="Выберите изображение"
            class="mt-3"
            hide-details
            outlined
            dense
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeUploadDialog" color="blue darken-1" text>
            Закрыть
          </v-btn>
          <v-btn @click.stop="uploadRequest" :disabled="!file" color="blue darken-1" text>
            Отправить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="getUploading" absolute>
          <v-progress-circular :size="50" color="indigo lighten-4" indeterminate/>
        </v-overlay>
      </v-card>
    </v-dialog>

    <!-- Модальное окно подтверждения удаления фото -->
    <v-dialog v-model="deleteImageDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Удалить избражение</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-bold">{{ typeTitle }}:</span>
          {{ selectedItem && selectedItem.name }}
          <v-alert type="error" class="mb-0 mt-2" text prominent>
            Внимание! Удаление изображения является крайней мерой, лучшим
            решением будет загрузка нового изображения.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop="closeDeleteImageDialog"
            color="blue darken-1"
            text
          >
            Закрыть
          </v-btn>
          <v-btn @click.stop="deleteImageRequest" color="error" text>
            Удалить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="deleteRequestInProgress" absolute>
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <!-- Модальное окно одобрения фото -->
    <v-dialog v-model="approvalImageDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Одобрить избражение</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-bold">{{ typeTitle }}:</span>
          {{ selectedItem && selectedItem.name }}
          <v-alert type="info" class="mb-0 mt-2" text prominent>
            Изображени будет удалено из этого списка.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeApprovalDialog" color="blue darken-1" text>
            Закрыть
          </v-btn>
          <v-btn @click.stop="approvalImageRequest" color="error" text>
            Одобрить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="updateRequestInProgress" absolute>
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import permissions from '@/common/permissions'
import * as catalogTypes from '@/common/catalogTypes'
import { routes } from '@/router'
import imagesApi from '@/services/imagesApi'
import BadImagesTable from './BadImagesTable'

export default {
  components: { BadImagesTable },
  props: {
    type: {
      type: String,
      default: catalogTypes.product
    }
  },
  created() {
    this.routes = routes
    this.catalogTypes = catalogTypes
    this.fetchItems()
  },
  watch: {
    type: function() {
      this.page = 1
      this.actionClearImagesBad()
    },
    computedWatch: function() {
      this.fetchItems()
    }
  },
  data: () => ({
    selectedItem: null,
    uploadDialog: false,
    file: null,
    deleteImageDialog: false,
    approvalImageDialog: false,
    page: 1,
    itemsPerPage: 10,
    updateRequestInProgress: false,
    deleteRequestInProgress: false
  }),
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('images', [
      'getImagesBad',
      'getImagesBadFetching',
      'getImagesBadCount',
      'getUploading'
    ]),
    computedWatch: function() {
      return {
        page: this.page,
        type: this.type
      }
    },
    typeTitle: function() {
      switch (this.type) {
        case catalogTypes.product:
          return 'Товар'
        case catalogTypes.structure:
          return 'Папка 1С'
        case catalogTypes.category:
          return 'Ветка ассортимента'
        case catalogTypes.brand:
          return 'Бренд'
      }

      return ''
    },
    items: function() {
      return this.getImagesBad.map(item => {
        const { entity } = item
        const itemData = {
          _1c_id: entity._1c_id,
          id: entity.id,
          image_id: item.id,
          name: entity.name,
          image: {
            preview: item.photo,
            original: item.photo
          },
          size: {
            height: item.height,
            width: item.width,
            width_warn: item.width_warn,
            height_warn: item.height_warn,
          }
        }
        if (this.type === catalogTypes.product) {
          itemData.article = entity.article
          itemData.structure = entity.structure
          itemData.category = entity.category
        }
        return itemData
      })
    },
    pageCount: function() {
      return Math.ceil(this.getImagesBadCount / this.itemsPerPage)
    },
    isAdmin: function() {
      return this.getCurrentUserHasPermission(permissions.appImagesAdmin)
    }
  },
  methods: {
    ...mapActions('images', [
      'actionFetchImagesBad',
      'actionClearImagesBad',
      'actionUploadImage'
    ]),
    fetchItems: function() {
      this.actionFetchImagesBad({
        type: this.type,
        page: this.page,
        itemsPerPage: this.itemsPerPage
      })
    },
    openUploadDialog: function(item) {
      this.uploadDialog = true
      this.selectedItem = item
    },
    closeUploadDialog: function() {
      this.uploadDialog = false
      this.selectedItem = null
      this.file = null
    },
    uploadRequest: function() {
      if (this.selectedItem && this.file) {
        this.actionUploadImage({
          type: this.type,
          id: this.selectedItem.id,
          file: this.file
        }).then(() => {
          this.fetchItems()
          this.closeUploadDialog()
        })
      }
    },
    openDeleteImageDialog: function(item) {
      this.deleteImageDialog = true
      this.selectedItem = item
    },
    closeDeleteImageDialog: function() {
      this.deleteImageDialog = false
      this.selectedItem = null
    },
    deleteImageRequest: function() {
      this.deleteRequestInProgress = true
      imagesApi.deleteImage(this.type, this.selectedItem.id).finally(() => {
        this.deleteRequestInProgress = false
        this.fetchItems()
        this.closeDeleteImageDialog()
      })
    },
    openApprovalDialog: function(item) {
      this.approvalImageDialog = true
      this.selectedItem = item
    },
    closeApprovalDialog: function() {
      this.approvalImageDialog = false
      this.selectedItem = null
    },
    approvalImageRequest: function() {
      this.updateRequestInProgress = true
      imagesApi
        .approvalBadImage(this.type, this.selectedItem.id)
        .finally(() => {
          this.updateRequestInProgress = false
          this.fetchItems()
          this.closeApprovalDialog()
        })
    }
  }
}
</script>
