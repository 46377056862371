import { render, staticRenderFns } from "./PaymentStatistic.vue?vue&type=template&id=c0a8b1e8&scoped=true&"
import script from "./PaymentStatistic.vue?vue&type=script&lang=js&"
export * from "./PaymentStatistic.vue?vue&type=script&lang=js&"
import style0 from "./PaymentStatistic.vue?vue&type=style&index=0&id=c0a8b1e8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0a8b1e8",
  null
  
)

export default component.exports