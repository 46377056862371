<template>
  <v-dialog v-model="show" max-width="1150">
    <template #activator="{ on, attrs }">
      <a @click.prevent v-on="on" v-bind="attrs">
        <v-icon class="open-statistic">mdi mdi-open-in-new</v-icon>
      </a>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ user.name }}</span>
      </v-card-title>
      <v-card-text>
        <h2>Выплаты:</h2>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Дата
              </th>
              <th class="text-left">
                Количество картинок
              </th>
              <th class="text-left">
                Статус выплаты
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(payment, index) in payments"
              :key="payment.id"
            >
              <td>{{ payment.created }}</td>
              <td>{{ getChangedModeratedImagesCount(index) }}</td>
              <td>
                <v-icon color="success">mdi mdi-check-bold</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="show = false" color="blue darken-1" text>
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PaymentStatistic',
  props: {
    user: Object
  },
  data() {
    return {
      show: false,
      payments: []
    }
  },
  methods: {
    ...mapActions('images', ['actionFetchUserPayments']),
    getChangedModeratedImagesCount(paymentIndex) {
      const currentPayment = this.payments[paymentIndex];
      if (paymentIndex === 0) return currentPayment.moderatedImagesCount;
      const preventPayment = this.payments[paymentIndex - 1];
      return (
        currentPayment.moderatedImagesCount -
        preventPayment.moderatedImagesCount
      )
    }
  },
  computed: {
    ...mapGetters('images', ['getUserPayments']),
  },
  watch: {
    show() {
      if (!this.getUserPayments(this.user.id)) {
        this.actionFetchUserPayments({userId: this.user.id}).then(() => {
            this.payments = this.getUserPayments(this.user.id);
          }
        )
      }
    }
  }

}
</script>
<style scoped lang="scss">
.open-statistic {
  color: unset;
}
</style>