import { render, staticRenderFns } from "./ModerationTable.vue?vue&type=template&id=4caf1950&"
import script from "./ModerationTable.vue?vue&type=script&lang=js&"
export * from "./ModerationTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports