<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="itemsPerPage"
        class="thin-table"
        hide-default-footer
        disable-sort
        dense
    >
      <template #item._1c_id="{ item }">
        <div v-if="item._1c_id">{{ item._1c_id }}</div>
        <div v-else class="font-italic font-weight-light">нет</div>
      </template>
      <template #item.info="{ item }">
        <div
            v-if="type == catalogTypes.product"
            class="pt-1 blue-grey--text lighten-2"
        >
          Артикул: {{ itemArticle(item) }}
        </div>
        <div class="py-1">
          <router-link :to="itemLink(item)">
            {{ item.name }}
          </router-link>
        </div>
        <v-breadcrumbs :items="ancestors(item)" class="pa-0 mb-1">
          <template #item="{ item }">
            <v-breadcrumbs-item>
            <span class="caption font-italic blue-grey--text lighten-1">
              {{ item.text }}
            </span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
      <template #item.search="{ item }">
        <div>
          <v-icon class="mr-1" small>mdi-magnify</v-icon>
          <a
              :href="
            encodeURI(`http://images.yandex.ru/yandsearch?_1c_id=${item._1c_id}&model_type=${type}&text=${getSearchText(item, type)}`)
          "
              target="_blank"
          >
            Яндекс
          </a>
        </div>
        <div v-if="(type === catalogTypes.product) && !!getBrandById(item.brand)?.link">
          <v-icon class="mr-1" small>mdi-magnify</v-icon>
          <a
              :href="
            encodeURI(`${getBrandById(item.brand)?.link}?_1c_id=${item._1c_id}&model_type=${type}`)
          "
              target="_blank"
          >
            Загрузить изображение с сайта бренда
          </a>
        </div>
      </template>
      <template #item.action="{ item }">
        <slot name="actions" :item="item"></slot>
      </template>
    </v-data-table>
  </div>

</template>

<script>
import * as catalogTypes from '@/common/catalogTypes'
import {catalogItemsTableMixin} from './mixins'
import {mapActions, mapState} from "vuex";

export default {
  mixins: [catalogItemsTableMixin],
  created() {
    this.catalogTypes = catalogTypes
    this.headers = [
      {
        text: 'Код 1С',
        value: '_1c_id',
        sortable: false,
        align: 'center',
        width: '10%'
      },
      {
        text: 'Информация',
        value: 'info',
        sortable: false,
        align: 'left',
        width: '70%'
      },
      {
        text: '',
        value: 'search',
        sortable: false,
        align: 'left',
        width: '10%'
      },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '20%'
      },
    ];
    if (!this.stock_brands.length && !this.stock_brands_fetching) {
      this.actionFetchStockBrands();
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('stock_brands', ['stock_brands_fetching', 'stock_brands']),
  },
  methods: {
    ...mapActions('stock_brands', [
      'actionFetchStockBrands',
    ]),
    getBrandById(brandId) {
      return this.stock_brands.find(brand => brand.id === brandId)
    },
    getSearchText(item, type) {
      let searchText = '';
      if (type === catalogTypes.product) {
        if (item.article) {
          searchText += item.article + ' ';
        }
        if (item.brand) {
          searchText += this.getBrandById(item.brand).name + ' ';
        }
        searchText += item.name
      } else if (type === catalogTypes.brand) {
        searchText += `Логотип бренда ${item.name} строительные материалы`
      } else if ([catalogTypes.category, catalogTypes.structure].includes(type)) {
        searchText += `${item.parent?.name} ${item.name}`
      }
      return searchText
    }
  }
}
</script>
