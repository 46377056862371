<template>
  <div>
    <v-progress-circular
      v-if="fetchingPremoderateImages"
      :size="36"
      color="indigo lighten-4"
      indeterminate
    ></v-progress-circular>
    <div
      v-else-if="hasPremoderateImages"
      class="mb-0 blue--text lighten-1--text"
    >
      Ожидает модерации
    </div>
    <v-btn
      v-else
      @click.stop="showUploadDialog = true"
      color="primary"
      small
      depressed
    >
      Загрузить
    </v-btn>

    <!-- Модальное окно выбра файла -->
    <v-dialog
      v-if="showUploadDialog"
      v-model="showUploadDialog"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">Загрузить избражение</span>
        </v-card-title>
        <v-card-text>
          <span class="font-weight-bold">{{ typeTitle }}:</span>
          {{ item && item.name }}
          <v-file-input
            v-model="file"
            accept="image/png, image/jpeg"
            prepend-icon="mdi-camera"
            label="Выберите изображение"
            class="mt-3"
            hide-details
            outlined
            dense
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="closeDialog" color="blue darken-1" text>
            Закрыть
          </v-btn>
          <v-btn
            @click.stop="uploadRequest"
            :disabled="!file"
            color="blue darken-1"
            text
          >
            Отправить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="getUploading" absolute>
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import imagesApi from '@/services/imagesApi'
import * as catalogTypes from '@/common/catalogTypes'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchPremoderateImages()
  },
  data: () => ({
    fetchingPremoderateImages: false,
    hasPremoderateImages: false,
    showUploadDialog: false,
    file: null
  }),
  computed: {
    ...mapGetters('images', ['getUploading']),
    typeTitle: function() {
      switch (this.type) {
        case catalogTypes.product:
          return 'Товар'
        case catalogTypes.structure:
          return 'Папка 1С'
        case catalogTypes.category:
          return 'Ветка ассортимента'
      }
      return ''
    }
  },
  methods: {
    ...mapActions('images', ['actionUploadImage']),
    fetchPremoderateImages: function() {
      this.fetchingPremoderateImages = true
      imagesApi
        .getModerate(this.type, {
          filterByTarget: this.item.id,
          filterUnverified: true
        })
        .then(data => {
          this.hasPremoderateImages = data.count > 0
        })
        .finally(() => {
          this.fetchingPremoderateImages = false
        })
    },
    closeDialog: function() {
      this.showUploadDialog = false
      this.file = null
    },
    uploadRequest: function() {
      if (this.item && this.file) {
        this.actionUploadImage({
          type: this.type,
          id: this.item.id,
          file: this.file
        }).then(() => {
          this.closeDialog()
          this.fetchPremoderateImages()
        })
      }
    }
  }
}
</script>
