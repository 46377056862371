<template>
  <div>
    <v-list dense>
      <v-list-item
        v-for="item in availableMenuItems"
        :key="item.key"
        :to="{ name: item.link }"
        :exact="item.exact"
        link
        dense
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider class="mt-3 mb-6"></v-divider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import permissions from '@/common/permissions'
import { routes } from '@/router'
import {imagesUploadReport} from "../../router/routes";

export default {
  data: () => ({
    menuItems: [
      {
        key: routes.imagesIndex,
        title: 'Главная',
        icon: 'mdi-monitor-dashboard',
        link: routes.imagesIndex,
        exact: true
      },
      {
        key: routes.imagesSearch,
        title: 'Поиск',
        icon: 'mdi-magnify',
        link: routes.imagesSearch
      },
      {
        key: routes.imagesModeration,
        title: 'Модерация',
        icon: 'mdi-image-search-outline',
        link: routes.imagesModeration,
        permission: permissions.appImagesAdmin
      },
      {
        key: routes.imagesEmpty,
        title: 'Нет изображений',
        icon: 'mdi-image-off-outline',
        link: routes.imagesEmpty
      },
      {
        key: routes.imagesBad,
        title: 'Плохие изображения',
        icon: 'mdi-alert-outline',
        link: routes.imagesBad
      },
      {
        key: routes.imagesStatsUploads,
        title: 'Статистика загрузок',
        icon: 'mdi-chart-bar',
        link: routes.imagesStatsUploads,
        exact: true
      },
      {
        key: routes.imagesStatsMy,
        title: 'Моя статистика',
        icon: 'mdi-account-badge-outline',
        link: routes.imagesStatsMy
      },
      {
        key: routes.imagesUploadReport,
        title: 'Отчет',
        icon: 'mdi-account-badge-outline',
        link: routes.imagesUploadReport
      }
    ]
  }),
  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    availableMenuItems: function() {
      return this.menuItems.filter(item =>
        item.permission
          ? this.getCurrentUserHasPermission(item.permission)
          : true
      )
    }
  }
}
</script>
