<template>
  <div :class="{
    'd-flex': 1,
    'flex-row': direction === 'row',
    'flex-column': direction === 'column',
    'justify-center': 1,
    'align-center': 1,
  }">
    <v-dialog v-model="show" max-width="500px" :persistent="moderationRequestInProgress">
      <template #activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" color="error" x-small class="ma-2" depressed>
          Отклонить
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">Отклонить изображение</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group
            v-model="rejectReason"
            label="Укажите причину отклонения:"
            hide-details
          >
            <v-radio
              v-for="item in rejectReasons"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
          <v-textarea
            v-if="rejectReason === rejectReasonType.other"
            v-model.trim="rejectMessage"
            label="Напишите причину отклонения"
            class="mt-6"
            hide-details
            outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="show = false" color="blue darken-1" text>
            Закрыть
          </v-btn>
          <v-btn @click="rejectCandidate" :disabled="!canReject" color="blue darken-1" text>
            Отправить
          </v-btn>
        </v-card-actions>
        <v-overlay color="white" :value="moderationRequestInProgress" absolute>
          <v-progress-circular
            :size="50"
            color="indigo lighten-4"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
    <v-btn class="ma-2" @click.stop="acceptCandidate" color="primary" x-small depressed>
      Одобрить
    </v-btn>
  </div>
</template>

<script>
import imagesApi from '@/services/imagesApi'
import * as catalogTypes from "@/common/catalogTypes";


const rejectReasonType = {
  quality: 'quality',
  watermark: 'watermark',
  other: 'other'
}

const rejectReasons = [
  {
    value: rejectReasonType.quality,
    label: 'Низкое качество'
  },
  {
    value: rejectReasonType.watermark,
    label: 'Водяной знак'
  },
  {
    value: rejectReasonType.other,
    label: 'Свой вариант'
  }
]

export default {
  name: "ModerateCandidateDialog",
  props: {
    id: Number, // candidate id
    type: String, // catalogTypes variant,
    direction: {
      type: String,
      default: 'row',
      validator: v => ['row', 'column'].indexOf(v) !== -1
    }
  },

  data: () => ({
    show: false,
    moderationRequestInProgress: false,

    rejectReasons,
    rejectReasonType,

    rejectReason: null,
    rejectMessage: null,
  }),

  methods: {
    acceptCandidate() {
      this.moderationRequestInProgress = true

      imagesApi.acceptImage(this.type, this.id).then(() => {
        this.show = false
        this.moderationRequestInProgress = false

        this.$emit('done')
      })
    },

    rejectCandidate() {
      this.moderationRequestInProgress = true

      let rejectReason = (
        this.rejectReason === rejectReasonType.other
          ? this.rejectMessage
          : this.rejectReasons.filter(r => r.value === this.rejectReason)[0].label
      )

      imagesApi.rejectImage(
        this.type,
        this.id,
        rejectReason
      ).finally(() => {
        this.show = false
        this.moderationRequestInProgress = false
        this.$emit('done')
      })
    }
  },

  watch: {
    moderationRequestInProgress(to) {
      this.$emit('fetching', to)
    },

    show(to) {
      if (to) {
        this.rejectReason = rejectReasonType.quality
      }
    }
  },

  computed: {
    canReject() {
      return (
        !this.moderationRequestInProgress
        && (
          [rejectReasonType.quality, rejectReasonType.watermark].indexOf(this.rejectReason) !== -1
          || (this.rejectReason === rejectReasonType.other && this.rejectMessage)
        )
      )
    }
  }
}
</script>

<style scoped>

</style>