<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    hide-default-footer
    class="thin-table"
    disable-sort
    dense
  >
    <template #item.info="{ item }">
      <div
        v-if="type == catalogTypes.product"
        class="pt-1 blue-grey--text lighten-2"
      >
        Артикул: {{ itemArticle(item) }}
      </div>
      <div class="py-1">
        <router-link :to="itemLink(item)">
          {{ item.name }}
        </router-link>
      </div>
      <v-breadcrumbs :items="ancestors(item)" class="pa-0 mb-1">
        <template #item="{ item }">
          <v-breadcrumbs-item>
            <span class="caption font-italic blue-grey--text lighten-1">
              {{ item.text }}
            </span>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
    <template #item.image="{ item }">
      <v-popup-image
        v-if="item.image"
        class="my-2"
        :src-preview="item.image.preview"
        :src-original="item.image.original"
      />
      <div v-else class="font-weight-light font-italic">нет</div>
    </template>
    <template #item.size="{ item }">
      <v-chip :color="item.size.width_warn ? 'error' : 'success'" small label>
        {{ item.size.width }}
      </v-chip>
      🗙
      <v-chip :color="item.size.height_warn ? 'error' : 'success'" small label>
        {{ item.size.height }}
      </v-chip>
    </template>
    <template #item.actions="{ item }">
      <slot name="actions" :item="item"></slot>
    </template>
  </v-data-table>
</template>

<script>
import * as catalogTypes from '@/common/catalogTypes'
import { catalogItemsTableMixin } from './mixins'

export default {
  mixins: [catalogItemsTableMixin],
  created() {
    this.catalogTypes = catalogTypes
    this.headers = [
      {
        text: 'Код 1С',
        value: '_1c_id',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Информация',
        value: 'info',
        sortable: false,
        align: 'left',
        width: '100%'
      },
      {
        text: 'Изображение',
        value: 'image',
        sortable: false,
        align: 'center',
        width: 110
      },
      {
        text: 'Размер (W x H)',
        value: 'size',
        sortable: false,
        align: 'center',
        width: 130
      },
      {
        text: 'Действия',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 130
      }
    ]
  }
}
</script>
