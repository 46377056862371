var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title mb-3"},[_vm._v("Моя статистика")]),_c('v-radio-group',{staticClass:"mt-1",attrs:{"hide-details":"","dense":""},model:{value:(_vm.selectedCatalogItemType),callback:function ($$v) {_vm.selectedCatalogItemType=$$v},expression:"selectedCatalogItemType"}},[_c('v-radio',{key:_vm.catalogTypes.product,attrs:{"value":_vm.catalogTypes.product},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"body-2"},[_vm._v("Товары")])]},proxy:true}])}),_c('v-radio',{key:_vm.catalogTypes.structure,attrs:{"value":_vm.catalogTypes.structure},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"body-2"},[_vm._v("Папки номенклатуры")])]},proxy:true}])}),_c('v-radio',{key:_vm.catalogTypes.category,attrs:{"value":_vm.catalogTypes.category},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"body-2"},[_vm._v("Ветки ассортимента")])]},proxy:true}])}),_c('v-radio',{key:_vm.catalogTypes.brand,attrs:{"value":_vm.catalogTypes.brand},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"body-2"},[_vm._v("Бренды")])]},proxy:true}])})],1),_c('v-tabs',{staticClass:"mb-6 mt-3",attrs:{"center-active":"","show-arrows":"","grow":""}},[_c('v-tab',{attrs:{"to":{
        name: _vm.routes.imagesStatsMy,
        params: { type: _vm.type, status: _vm.imageStatus.moderation }
      },"exact":"","link":""}},[_vm._v(" На проверке ")]),_c('v-tab',{attrs:{"to":{
        name: _vm.routes.imagesStatsMy,
        params: { type: _vm.type, status: _vm.imageStatus.rejected }
      },"exact":"","link":""}},[_vm._v(" Отклоненные ")])],1),_c('v-overlay',{attrs:{"color":"white","value":_vm.getImagesModerationFetching,"absolute":""}},[_c('v-progress-circular',{attrs:{"size":50,"color":"indigo lighten-4","indeterminate":""}})],1),(_vm.status === _vm.imageStatus.moderation)?_c('ModerationTable',{attrs:{"items":_vm.items,"type":_vm.type,"itemsPerPage":_vm.itemsPerPage}}):_c('RejectedTable',{attrs:{"items":_vm.items,"type":_vm.type,"itemsPerPage":_vm.itemsPerPage}}),(_vm.pageCount)?_c('div',{staticClass:"text-center pt-4"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"12"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }