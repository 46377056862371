import {mapGetters} from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import {routes} from '@/router'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    items: Array,
    itemsPerPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('stock_categories', ['getStockCategoryAncestorsById']),
    ...mapGetters('stock_tree', ['getStockTreeAncestorsById']),
    ...mapGetters('stock_brands', ['getStockBrandCategoryById']),
  },
  methods: {
    itemArticle: function (item) {
      return item.article || 'нет'
    },
    itemLink: function (item) {
      switch (this.type) {
        case catalogTypes.product:
          return {
            name: routes.catalogProduct,
            params: {
              catalogType: catalogTypes.structure,
              productId: item.id
            }
          }
        case catalogTypes.structure:
          return {
            name: routes.catalogDetail,
            params: {
              catalogType: catalogTypes.structure,
              catalogId: item.id
            }
          }
        case catalogTypes.category:
          return {
            name: routes.catalogDetail,
            params: {catalogType: catalogTypes.category, catalogId: item.id}
          }

        case catalogTypes.brand:
          return {
            name: routes.brandsDetail,
            params: {id: item.id},
          }
      }
      return {name: routes.catalogIndex}
    },
    ancestors: function (item) {
      let ancestors = []
      switch (this.type) {
        case catalogTypes.structure:
          ancestors = this.getStockCategoryAncestorsById(item.id)
          ancestors.shift()
          break
        case catalogTypes.category:
          ancestors = this.getStockTreeAncestorsById(item.id)
          ancestors.shift()
          break
        case catalogTypes.product:
          ancestors = this.getStockCategoryAncestorsById(item.structure)
          break
      }
      return ancestors.reverse().map(item => ({
        text: item.name,
        exact: false,
        to: {
          name: routes.catalogDetail,
          params:
            this.type === catalogTypes.category
              ? {
                catalogType: catalogTypes.category,
                catalogId: item.category
              }
              : {
                catalogType: catalogTypes.structure,
                catalogId: item.structure
              }
        }
      }))
    }
  }
}
