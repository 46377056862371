<template>
  <div>
    <div class="title text-center">Статистика изображений каталога. Данные актуальны для {{ countProblems.date }}</div>
    <div v-if="getProblemsStatsFetching" class="text-center my-6">
      <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="getProblemsStats.length" class="my-6">
      <div class="pt-3 subtitle-2">Проблемные картинки на данный момент</div>
      <v-simple-table>
        <template #default>
          <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-center">Товары</th>
            <th class="text-center">Папки</th>
            <th class="text-center">Ветки ассортимента</th>
            <th class="text-center">Бренды</th>
          </tr>
          </thead>
          <tbody>
          <tr class="font-weight-bold">
            <td>Всего элементов в справочнике</td>
            <td class="text-center">
              {{ countProblems.product.total }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.total }}
            </td>
            <td class="text-center">
              {{ countProblems.category.total }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.total }}
            </td>
          </tr>
          <tr>
            <td>Всего активных элементов с изображениями</td>
            <td class="text-center">
              {{ countProblems.product.active_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.active_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.active_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.active_with_image }}
            </td>
          </tr>
          <tr>
            <td>Всего активных элементов без изображений</td>
            <td class="text-center">
              {{ countProblems.product.active_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.active_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.active_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.active_without_image }}
            </td>
          </tr>
          <tr>
            <td>Всего неотображаемых элементов с изображениями</td>
            <td class="text-center">
              {{ countProblems.product.nonactive_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.nonactive_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.nonactive_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.nonactive_with_image }}
            </td>
          </tr>
          <tr>
            <td>Всего неотображаемых элементов без изображений</td>
            <td class="text-center">
              {{ countProblems.product.nonactive_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.nonactive_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.nonactive_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.nonactive_without_image }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-divider></v-divider>
      <v-simple-table>
        <template #default>
          <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-center">Товары</th>
            <th class="text-center">Папки</th>
            <th class="text-center">Ветки ассортимента</th>
            <th class="text-center">Бренды</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Элементов в я_Корзина с изображениями</td>
            <td class="text-center">
              {{ countProblems.product.trash_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.trash_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.trash_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.trash_with_image }}
            </td>
          </tr>
          <tr>
            <td>Элементов в я_Корзина без изображений</td>
            <td class="text-center">
              {{ countProblems.product.trash_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.trash_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.trash_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.trash_without_image }}
            </td>
          </tr>
          <tr>
            <td>Элементов в я_ЗАКАЗНОЙ_ТОВАР с изображениями</td>
            <td class="text-center">
              {{ countProblems.product.custom_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.custom_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.custom_with_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.custom_with_image }}
            </td>
          </tr>
          <tr>
            <td>Элементов в я_ЗАКАЗНОЙ_ТОВАР без изображений</td>
            <td class="text-center">
              {{ countProblems.product.custom_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.structure.custom_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.category.custom_without_image }}
            </td>
            <td class="text-center">
              {{ countProblems.brand.custom_without_image }}
            </td>
          </tr>

          </tbody>
        </template>
      </v-simple-table>
      <!--      <v-divider></v-divider>-->
      <!--      <div class="pt-6">-->
      <!--        <div class="subtitle-2">Проблемные картинки в процентах</div>-->
      <!--        <v-line-chart :chart-data="chartData" :options="chartOptions"/>-->
      <!--      </div>-->
    </div>
    <div v-else class="my-6 font-italic font-weight-light text-center">
      Нет данных статистики
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters, mapActions} from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import {routes} from '@/router'

export default {
  created() {
    this.actionFetchProblemsStats({
      fromDate: moment()
          .subtract(60, 'days')
          .format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD')
    })
    this.catalogTypes = catalogTypes
  },
  destroyed() {
    this.actionClearProblemsStats()
  },
  data: () => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: 'index',
        intersect: false
      }
    }
  }),
  computed: {
    ...mapGetters('images', ['getProblemsStats', 'getProblemsStatsFetching']),
    chartData: function () {
      const chart = this.getProblemsStats.reduce(
          (accum, item) => {
            // products
            const totalProductsProblems = item['products_with_bad_images'] + item['products_without_images']
            accum.product.push(parseFloat((totalProductsProblems / item['total_products']) * 100).toFixed(2))

            // structures
            const totalStructuresProblems = item['structures_with_bad_images'] + item['structures_without_images']
            accum.structure.push(parseFloat((totalStructuresProblems / item['total_structures']) * 100).toFixed(2))

            // category
            const totalCategoriesProblems = item['categories_with_bad_images'] + item['categories_without_images']
            accum.category.push(parseFloat((totalCategoriesProblems / item['total_categories']) * 100).toFixed(2))

            // brands
            const totalBrandsProblems = item['brands_with_bad_images'] + item['brands_without_images']
            accum.brand.push(parseFloat((totalBrandsProblems / item['total_brands']) * 100).toFixed(2))

            return accum
          },
          {
            product: [],
            structure: [],
            category: [],
            brand: [],
          }
      )
      return {
        labels: this.getProblemsStats.map(item => item.date),
        datasets: [
          {
            label: 'Товары',
            backgroundColor: '#E57373',
            borderColor: '#E57373',
            fill: false,
            data: chart.product
          },
          {
            label: 'Папки 1С',
            backgroundColor: '#64B5F6',
            borderColor: '#64B5F6',
            fill: false,
            data: chart.structure
          },
          {
            label: 'Ветки ассортимента',
            backgroundColor: '#81C784',
            borderColor: '#81C784',
            fill: false,
            data: chart.category
          },
          {
            label: 'Бренды',
            backgroundColor: '#FFEB3B',
            borderColor: '#FFEB3B',
            fill: false,
            data: chart.brand
          }
        ]
      }
    },
    countProblems: function () {
      if (this.getProblemsStats.length) {
        const lastItem = this.getProblemsStats[this.getProblemsStats.length - 1]

        return {
          product: {
            total: this.toLocalNumber(lastItem['total_products']),
            active_with_image: this.toLocalNumber(lastItem['total_active_products_with_image']),
            active_without_image: this.toLocalNumber(lastItem['total_active_products_without_image']),
            nonactive_with_image: this.toLocalNumber(lastItem['total_nonactive_products_with_image']),
            nonactive_without_image: this.toLocalNumber(lastItem['total_nonactive_products_without_image']),
            trash_with_image: this.toLocalNumber(lastItem['products_in_trash_with_image']),
            trash_without_image: this.toLocalNumber(lastItem['products_in_trash_without_image']),
            custom_with_image: this.toLocalNumber(lastItem['products_custom_with_image']),
            custom_without_image: this.toLocalNumber(lastItem['products_custom_without_image']),
          },
          structure: {
            total: this.toLocalNumber(lastItem['total_structures']),
            active_with_image: this.toLocalNumber(lastItem['total_active_structures_with_image']),
            active_without_image: this.toLocalNumber(lastItem['total_active_structures_without_image']),
            nonactive_with_image: this.toLocalNumber(lastItem['total_nonactive_structures_with_image']),
            nonactive_without_image: this.toLocalNumber(lastItem['total_nonactive_structures_without_image']),
            trash_with_image: this.toLocalNumber(lastItem['structures_in_trash_with_image']),
            trash_without_image: this.toLocalNumber(lastItem['structures_in_trash_without_image']),
            custom_with_image: this.toLocalNumber(lastItem['structures_custom_with_image']),
            custom_without_image: this.toLocalNumber(lastItem['structures_custom_without_image']),
          },
          category: {
            total: this.toLocalNumber(lastItem['total_categories']),
            active_with_image: this.toLocalNumber(lastItem['total_active_categories_with_image']),
            active_without_image: this.toLocalNumber(lastItem['total_active_categories_without_image']),
            nonactive_with_image: this.toLocalNumber(lastItem['total_nonactive_categories_with_image']),
            nonactive_without_image: this.toLocalNumber(lastItem['total_nonactive_categories_without_image']),
            trash_with_image: this.toLocalNumber(lastItem['categories_in_trash_with_image']),
            trash_without_image: this.toLocalNumber(lastItem['categories_in_trash_without_image']),
            custom_with_image: this.toLocalNumber(lastItem['categories_custom_with_image']),
            custom_without_image: this.toLocalNumber(lastItem['categories_custom_without_image']),
          },
          brand: {
            total: this.toLocalNumber(lastItem['total_brands']),
            active_with_image: this.toLocalNumber(lastItem['total_active_brands_with_image']),
            active_without_image: this.toLocalNumber(lastItem['total_active_brands_without_image']),
            nonactive_with_image: this.toLocalNumber(lastItem['total_nonactive_brands_with_image']),
            nonactive_without_image: this.toLocalNumber(lastItem['total_nonactive_brands_without_image']),
            trash_with_image: this.toLocalNumber(lastItem['brands_in_trash_with_image']),
            trash_without_image: this.toLocalNumber(lastItem['brands_in_trash_without_image']),
            custom_with_image: this.toLocalNumber(lastItem['brands_custom_with_image']),
            custom_without_image: this.toLocalNumber(lastItem['brands_custom_without_image']),
          },
          date: lastItem['date']
        }
      }
      return null
    }
  },
  methods: {
    ...mapActions('images', [
      'actionFetchProblemsStats',
      'actionClearProblemsStats'
    ]),
    linkEmptyImages: function (type) {
      return {name: routes.imagesEmpty, params: {type}}
    },
    linkBadImages: function (type) {
      return {name: routes.imagesBad, params: {type}}
    },
    toLocalNumber(number) {
      return number.toLocaleString('ru-RU');
    }
  }
}
</script>
