<template>
  <div>
    <div class="title mb-3">Моя статистика</div>

    <v-radio-group
      v-model="selectedCatalogItemType"
      class="mt-1"
      hide-details
      dense
    >
      <v-radio :key="catalogTypes.product" :value="catalogTypes.product">
        <template #label>
          <span class="body-2">Товары</span>
        </template>
      </v-radio>
      <v-radio :key="catalogTypes.structure" :value="catalogTypes.structure">
        <template #label>
          <span class="body-2">Папки номенклатуры</span>
        </template>
      </v-radio>
      <v-radio :key="catalogTypes.category" :value="catalogTypes.category">
        <template #label>
          <span class="body-2">Ветки ассортимента</span>
        </template>
      </v-radio>
      <v-radio :key="catalogTypes.brand" :value="catalogTypes.brand">
        <template #label>
          <span class="body-2">Бренды</span>
        </template>
      </v-radio>
    </v-radio-group>

    <v-tabs class="mb-6 mt-3" center-active show-arrows grow>
      <v-tab
        :to="{
          name: routes.imagesStatsMy,
          params: { type, status: imageStatus.moderation }
        }"
        exact
        link
      >
        На проверке
      </v-tab>
      <v-tab
        :to="{
          name: routes.imagesStatsMy,
          params: { type, status: imageStatus.rejected }
        }"
        exact
        link
      >
        Отклоненные
      </v-tab>
    </v-tabs>

    <!-- Лоадер - загрузка элементов -->
    <v-overlay color="white" :value="getImagesModerationFetching" absolute>
      <v-progress-circular
        :size="50"
        color="indigo lighten-4"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- Таблицы -->
    <ModerationTable
      v-if="status === imageStatus.moderation"
      :items="items"
      :type="type"
      :itemsPerPage="itemsPerPage"
    ></ModerationTable>
    <RejectedTable
      v-else
      :items="items"
      :type="type"
      :itemsPerPage="itemsPerPage"
    ></RejectedTable>
    <div v-if="pageCount" class="text-center pt-4">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="12"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as catalogTypes from '@/common/catalogTypes'
import { routes } from '@/router'
import ModerationTable from './ModerationTable'
import RejectedTable from './RejectedTable'

const imageStatus = {
  moderation: 'moderation',
  rejected: 'rejected'
}

export default {
  components: { ModerationTable, RejectedTable },
  props: {
    type: {
      type: String,
      default: catalogTypes.product
    },
    status: {
      type: String,
      default: imageStatus.moderation
    }
  },
  created() {
    this.routes = routes
    this.catalogTypes = catalogTypes
    this.imageStatus = imageStatus
    this.fetchItems()
  },
  destroyed() {
    this.actionClearImagesModeration()
  },
  data: function() {
    return {
      selectedCatalogItemType: this.type,
      itemsFetching: false,
      itemsPerPage: 10,
      page: 1
    }
  },
  watch: {
    $route() {
      this.page = 1
      this.actionClearImagesModeration()
      this.fetchItems()
    },
    page() {
      this.fetchItems()
    },
    selectedCatalogItemType: function(type) {
      const { status } = this
      this.$router.push({
        name: routes.imagesStatsMy,
        params: { type, status }
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('images', [
      'getImagesModerationFetching',
      'getImagesModeration',
      'getImagesModerationCount'
    ]),
    items: function() {
      return this.getImagesModeration.map(item => {
        const { target, image } = item
        const itemData = {
          _1c_id: target._1c_id,
          candidate_id: item.id,
          id: target.id,
          name: target.name,
          images: {
            current: null,
            suggest: {
              preview: image,
              original: image
            }
          }
        }
        if (this.type === catalogTypes.product) {
          itemData.structure = target.structure
          itemData.category = target.category
        }
        // moderation
        if (this.status === imageStatus.moderation) {
          if (this.type === catalogTypes.product) {
            if (target.images && target.images.length) {
              itemData.images.current = {
                preview: target.images[0].url,
                original: target.images[0].url
              }
            }
          } else {
            if (target.preview) {
              itemData.images.current = {
                preview: target.preview,
                original: target.preview
              }
            }
          }
        }
        // rejected
        if (this.status === imageStatus.rejected) {
          itemData.message = item.reject_reason
        }
        return itemData
      })
    },
    pageCount: function() {
      return Math.ceil(this.getImagesModerationCount / this.itemsPerPage)
    }
  },
  methods: {
    ...mapActions('images', [
      'actionFetchImagesModeration',
      'actionClearImagesModeration'
    ]),
    fetchItems: function() {
      const params = {
        type: this.type,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        filterByUploader: this.currentUser.id
      }
      if (this.status === imageStatus.moderation) {
        params.filterUnverified = true
      }
      if (this.status === imageStatus.rejected) {
        params.filterRejected = true
      }
      this.actionFetchImagesModeration(params)
    }
  }
}
</script>
