<template>
  <div>
    <div class="title mb-3">Изображения на проверку</div>
    <v-tabs class="mb-6" center-active show-arrows grow>
      <v-tab :to="{ name: routes.imagesModeration, params: { type: catalogTypes.product } }" exact link>
        Товары
      </v-tab>
      <v-tab :to="{ name: routes.imagesModeration, params: { type: catalogTypes.structure } }" exact link>
        Папки 1С
      </v-tab>
      <v-tab :to="{ name: routes.imagesModeration, params: { type: catalogTypes.category } }" exact link>
        Ветки ассортимента
      </v-tab>
      <v-tab :to="{ name: routes.imagesModeration, params: { type: catalogTypes.brand } }" exact link>
        Бренды
      </v-tab>
    </v-tabs>

    <!-- Лоадер - загрузка элементов -->
    <v-overlay
        color="white"
        :value="getImagesModerationFetching || moderationRequestInProgress"
        absolute
    >
      <v-progress-circular
          :size="50"
          color="indigo lighten-4"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <!-- Таблица -->
    <ModerationTable
        :items="items"
        :type="type"
        :items-per-page="itemsPerPage"
        actions
    >
      <template #actions="{ item }">
        <ModerateCandidateDialog
            @fetching="this.moderationRequestInProgress = $event"
            @done="fetchItems"
            :type="type"
            :id="item.image_id"
            direction="column"
        />
        <div>
          <v-icon class="mr-1" small>mdi-magnify</v-icon>
          <a
              :href="
            encodeURI(`http://images.yandex.ru/yandsearch?text=${getSearchText(item, type)}`)
          "
              target="_blank"
          >
            Проверить на Яндексе
          </a>
        </div>
      </template>
    </ModerationTable>

    <div v-if="pageCount" class="text-center pt-4">
      <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="12"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'
import permissions from '@/common/permissions'
import * as catalogTypes from '@/common/catalogTypes'
import {routes} from '@/router'
import ModerationTable from './ModerationTable'
import ModerateCandidateDialog from './dialogs/ModerateCandidateDialog.vue'

export default {
  components: {ModerateCandidateDialog, ModerationTable},
  props: {
    type: {
      type: String,
      default: catalogTypes.product
    }
  },
  created() {
    if (this.getCurrentUserHasPermission(permissions.appImagesAdmin)) {
      this.routes = routes
      this.catalogTypes = catalogTypes
      this.fetchItems()
    } else {
      this.$router.push({name: routes.imagesIndex})
    }
  },
  destroyed() {
    this.actionClearImagesModeration()
  },
  watch: {
    type: function () {
      this.page = 1
      this.actionClearImagesModeration()
    },
    computedWatch: function () {
      this.fetchItems()
    }
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    moderationRequestInProgress: false,
    dialog: false,
    currentPopupImage: null
  }),
  computed: {
    ...mapState('stock_brands', ['stock_brands']),
    ...mapGetters('auth', ['getCurrentUserHasPermission']),
    ...mapGetters('stock_tree', ['getStockTreeById']),
    ...mapGetters('stock_categories', ['getStockCategoryById']),
    ...mapGetters('images', [
      'getImagesModerationFetching',
      'getImagesModeration',
      'getImagesModerationCount'
    ]),
    computedWatch: function () {
      return {
        page: this.page,
        type: this.type
      }
    },
    items: function () {
      return this.getImagesModeration.map(item => {
        const {target, image} = item
        const itemData = {
          _1c_id: target._1c_id,
          id: target.id,
          image_id: item.id,
          name: target.name,
          images: {
            current: null,
            suggest: {
              preview: image,
              original: image
            }
          }
        }
        if (this.type === catalogTypes.product) {
          itemData.article = target.article
          itemData.structure = target.structure
          itemData.category = target.category
          itemData.brand = target.brand
          if (target.images && target.images.length) {
            itemData.images.current = {
              preview: target.images[0].url,
              original: target.images[0].url
            }
          }
        } else if (this.type === catalogTypes.structure) {
          itemData.parent = this.getStockCategoryById(target.parent);
        } else if (this.type === catalogTypes.category) {
          itemData.parent = this.getStockTreeById(target.parent);
        } else {
          if (target.preview) {
            itemData.images.current = {
              preview: target.preview,
              original: target.preview
            }
          }
        }
        return itemData
      })
    },
    pageCount: function () {
      return Math.ceil(this.getImagesModerationCount / this.itemsPerPage)
    }
  },
  methods: {
    ...mapActions('images', [
      'actionFetchImagesModeration',
      'actionClearImagesModeration'
    ]),
    fetchItems: function () {
      this.actionFetchImagesModeration({
        type: this.type,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        filterUnverified: true
      })
    },
    getBrandById(brandId) {
      return this.stock_brands.find(brand => brand.id === brandId)
    },
    getSearchText(item, type) {
      let searchText = '';
      if (type === catalogTypes.product) {
        if (item.article) {
          searchText += item.article + ' ';
        }
        if (item.brand) {
          searchText += this.getBrandById(item.brand).name + ' ';
        }
        searchText += item.name
      } else if (type === catalogTypes.brand) {
        searchText += `Логотип бренда ${item.name} строительные материалы`
      } else if ([catalogTypes.category, catalogTypes.structure].includes(type)) {
        searchText += `${item.parent?.name} ${item.name}`
      }
      return searchText
    }
  }
}
</script>
