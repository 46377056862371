<template>
  <div class="popup" @click.stop="openDialog">
    <v-dialog v-model="isOpen" max-width="50%" @keydown.esc="cancel">
      <v-card class="popup__dialog">
        <div class="popup__close" @click.stop="cancel">
          X
        </div>
        <v-img class="popup__image" :src="getImageUrl(image)" alt="" contain/>
      </v-card>
    </v-dialog>
    <img
        :src="getImageUrl(imagePreview)"
        style="width: 100%; max-width: 100%; height: auto"
    />
  </div>
</template>

<script>
import noPhotoImg from "@/assets/images/nophoto.png";

const mediaHost = process.env.VUE_APP_MEDIA_HOST
export default {
  name: "APopupImage",
  props: {
    image: null,
    imagePreview: null
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    getImageUrl(localImageUrl) {
      const src = localImageUrl
      return src ? `${mediaHost}${src}` : noPhotoImg
    },
    openDialog() {
      this.isOpen = true
    },
    cancel() {
      this.isOpen = false
    }
  }
}
</script>


<style scoped lang="scss">
.popup {

  cursor: pointer;

  &__dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 4%;
  }

  &__image {
    max-width: 80%;
  }

  &__close {
    margin-left: auto;
    margin-top: 1%;
    margin-right: 2%;
    font-size: 25px;
    cursor: pointer;
  }
}
</style>